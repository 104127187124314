import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { merge } from 'lodash';

import { AUTH_METHOD, getStoredToken } from './../utils/auth';

function setupRequest() {
  function onResponse<D>(response: AxiosResponse<D>): D {
    return response.data;
  }

  function onResponseError(error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error);
  }

  function onRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    const token = getStoredToken();
    if (!token) return config;

    const defaultConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `${AUTH_METHOD} ${token}`,
        'Content-Type': 'application/json',
      },
    };

    return merge(defaultConfig, config);
  }

  function onRequestError(error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error);
  }

  const instance = axios.create({
    baseURL: 'https://api.mercadolibre.com',
  });

  instance.interceptors.response.use(onResponse, onResponseError);
  instance.interceptors.request.use(onRequest, onRequestError);

  return instance;
}

export const request = setupRequest();
