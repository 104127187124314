import { LOCAL_TOKEN_KEY } from "../../constants/local";

export const AUTH_METHOD = 'Bearer';

export function setStoreToken(token: string) {
  return sessionStorage.setItem(LOCAL_TOKEN_KEY, token);
}

export function getStoredToken() {
  return sessionStorage.getItem(LOCAL_TOKEN_KEY);
}

export function removeStoredToken() {
  return sessionStorage.removeItem(LOCAL_TOKEN_KEY);
}
