import { Instagram, WhatsApp } from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { LogoMLSVG } from "../../assets/logoML";
import Nav from "../nav";
import ProductList from "../produtos";
import "./../theme/style.css";
import "./style.css";

export const Home = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <Nav>
        <Grid gap="2rem" container height={"100%"}>
          <Box
            component="nav"
            className="py-5 header-background"
            width="100%"
            height={500}
          />
          <Box
            display={"flex"}
            justifyContent="end"
            alignItems="center"
            width="100%"
          >
            <Grid
              gap={2}
              direction="column"
              container
              justifyContent="end"
              alignItems="end"
              sx={{
                borderRadius: "1rem 0 0 1rem",
                position: "absolute",
                zIndex: 10,
                width: isMobile ? "80%" : "50%",
                top: 200,
                padding: "2rem",
                color: "white",
                background: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <Typography fontWeight={900} variant="h4">
                Guidões customizados
              </Typography>
              <Typography variant="body1">
                Personalize sua moto custom com nossos guidões exclusivos,
                criando um visual único e autêntico.
              </Typography>
            </Grid>
          </Box>
          <Grid
            container
            width="100%"
            justifyContent="center"
            alignItems="center"
            padding="0 0rem 4rem 0rem"
          >
            <ProductList pageSize={1} />
            <Paper
              sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
              elevation={3}
            >
              <BottomNavigation
                showLabels
                sx={{ backgroundColor: "#000", color: "#fff" }}
              >
                <BottomNavigationAction
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/garagecustom77",
                      "_blank"
                    );
                  }}
                  sx={{ color: "#fff" }}
                  label="Instagram"
                  icon={<Instagram sx={{ color: "#fff" }} />}
                />
                <BottomNavigationAction
                  onClick={() => {
                    window.open(
                      "https://lista.mercadolivre.com.br/_CustId_23351196?item_id=MLB929521516&category_id=MLB243235&seller_id=23351196&client=recoview-selleritems&recos_listing=true",
                      "_blank"
                    );
                  }}
                  sx={{ color: "#fff" }}
                  label="Mercado Livre"
                  icon={<LogoMLSVG />}
                ></BottomNavigationAction>

                <BottomNavigationAction
                  onClick={() => {
                    window.open(
                      "https://api.whatsapp.com/send?phone=551999420417",
                      "_blank"
                    );
                  }}
                  sx={{ color: "#fff" }}
                  label="(19) 99942-0417"
                  icon={<WhatsApp sx={{ color: "#fff" }} />}
                />
              </BottomNavigation>
            </Paper>
          </Grid>
        </Grid>
      </Nav>
    </>
  );
};
