import { Box, Grid, Tab, Tabs, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { getProducts, postAuth } from "../../api/products";
import { QUERY_KEYS } from "../../constants/local";
import { Product } from "../../types/produtos";
import { CardMUI } from "../card";

interface Props {
  pageSize: number;
}

const ProductList: React.FC<Props> = ({ pageSize }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  useEffect(() => {
    async function auth() {
      const response = await postAuth();
      sessionStorage.setItem("token", response.data.access_token);
      sessionStorage.setItem("exp", response.data.expires_in);
    }

    auth();
  }, []);

  // const { data: productsIds } = useQuery(
  //   [QUERY_KEYS.PRODUCTS, pageSize],
  //   async () => await getProductsIds(),
  //   {
  //     enabled: true,
  //   }
  // );

  const items = [
    "MLB929521516",
    "MLB931112317",
    "MLB950944091",
    "MLB967181400",
    "MLB1163723193",
    "MLB1167636000",
    "MLB1174284020",
    "MLB1197088630",
    "MLB1212712415",
    "MLB1292026119",
    "MLB1317559872",
    "MLB1387576240",
    "MLB1389919717",
    "MLB1449421792",
    "MLB1583751655",
    "MLB1604563656",
    "MLB1768801437",
    "MLB1768797045",
    "MLB1768792541",
    "MLB1768792209",
    "MLB1789393549",
    "MLB1789390533",
    "MLB1903777062",
    "MLB1903766458",
    "MLB1903761274",
    "MLB1904886692",
    "MLB2003446856",
    "MLB2009475716",
    "MLB2043123173",
    "MLB2043011182",
    "MLB2043011066",
    "MLB2177692440",
    "MLB2177675912",
    "MLB2208103185",
    "MLB2209756022",
    "MLB1536907618",
    "MLB1462634642",
    "MLB3141071348",
  ];

  const { data: products } = useQuery(
    [QUERY_KEYS.PRODUCTS],
    async () => {
      let allProducts: Product[] = [];
      let page = 0;
      let moreProductsAvailable = true;

      while (moreProductsAvailable) {
        const response = await getProducts(
          items.slice(page * 20, (page + 1) * 20)
        );

        if (response.length < 20) {
          moreProductsAvailable = false;
          allProducts = allProducts.concat(response);
          page++;
        } else {
          allProducts = allProducts.concat(response);
          page++;
        }
      }

      return await allProducts;
    },
    {
      enabled: true,
    }
  );

  // Mapeie cada categoria para os produtos correspondentes
  // Crie um mapa de categorias para as strings que você está procurando nos nomes dos produtos,
  const categories: string[] = [
    "Todos",
    "Ape Hanger",
    "Ape Hanger Curved",
    "Diablo",
    "Diablo Curved",
    "Vulcan 650 S Diablo Curved",
    "Z Bar",
    "Zed",
  ];

  // Agrupe os produtos com base nas categorias
  const categorizedProducts: Record<string, Product[]> = {};

  products?.forEach((product) => {
    const productName = product.body.title; // Substitua isso pelo campo correto que representa o nome do produto
    const category = findCategory(productName);

    if (!categorizedProducts[category]) {
      categorizedProducts[category] = [];
    }
    if (!categorizedProducts["Todos"]) {
      categorizedProducts["Todos"] = [];
    }
    categorizedProducts["Todos"].push(product);
    categorizedProducts[category].push(product);
  });

  // Função para encontrar a categoria com base no nome do produto
  function findCategory(productName: string): string {
    for (const category of categories) {
      if (
        productName?.toLowerCase()?.replaceAll("-", " ")?.includes(category.toLowerCase())
      ) {
        // Verifique se o nome do produto contém a categoria
        if (productName.includes("Curved") && !category.endsWith("Curved")) {
          continue; // Pule categorias que não são "Curved" quando "Curved" está no nome
        }
        if (productName.includes("Special") && !category.endsWith("Special")) {
          continue; // Pule categorias que não são "Curved" quando "Curved" está no nome
        }
        if (
          productName.includes("Guidão Vulcan 650 S") &&
          !category.startsWith("Vulcan 650 S")
        ) {
          continue; // Pule categorias que não são "Curved" quando "Curved" está no nome
        }
        return category;
      }
    }
    return "Outros"; // Se a categoria não for encontrada, use "Outros"
  }

  for (const category in categorizedProducts) {
    if (categorizedProducts.hasOwnProperty(category)) {
      categorizedProducts[category].sort((a, b) => {
        const productNameA = a.body.title?.toUpperCase(); // Converte para maiúsculas para ordenação insensível a maiúsculas/minúsculas
        const productNameB = b.body.title?.toUpperCase();
        if (productNameA < productNameB) return -1;
        if (productNameA > productNameB) return 1;
        return 0;
      });
    }
  }

  return (
    <Box sx={{ width: "100%", marginTop: "-4rem" }}>
      <Tabs
        sx={{ backgroundColor: "black", color: "white" }}
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="scrollable"
      >
        {categories.map((category, index) => (
          <Tab key={index} label={category} sx={{ color: "white" }} />
        ))}
      </Tabs>
      <Grid container gap={2} justifyContent="center" padding={2}>
        {categorizedProducts[categories[value]]?.map(
          (product, productIndex) =>
            product.body.status === "active" && (
              <Grid
                key={productIndex}
                item
                xs={12}
                sm={3}
                justifyContent="center"
              >
                <CardMUI variant={product} />
              </Grid>
            )
        )}
      </Grid>
    </Box>
  );
};

export default ProductList;
