import { request } from "..";
import { Product } from "../../types/produtos";
import { getStoredToken } from "../../utils/auth";
export type PaginationResponse<D extends Dict> = {
  data: D[] | null;
  pagination: {
    total: number;
    offset?: number;
    limit?: number;
  };
};

export async function getProductsIds() {
  var axios = require("axios").default;

  var options = {
    method: "GET",
    url: "https://api.mercadolibre.com/users/23351196/items/search",
    params: { limit: "50", offset: "0" },
    headers: {
      Authorization: "Bearer " + getStoredToken(),
    },
  };

  axios
    .request(options)
    .then(function (response: { data: any }) {
      console.log(response.data);
    })
    .catch(function (error: any) {
      console.error(error);
    });
}

export async function getProducts(items: string[]) {
  return await request.get<Product[]>(`/items?ids=${items}`);
}

export async function postAuth() {
  var axios = require("axios").default;

  var options = {
    headers: {
      accept: "application/json",
      "content-type": "application/x-www-form-urlencoded",
    },
    data: {
      grant_type: "client_credentials",
      client_id: "5341963902678505",
      client_secret: "NsUi6Nfq942ASALgWRafTi9cARjFrgrn",
    },
  };

  return await axios.post(
    "https://api.mercadopago.com/oauth/token  ",
    options.data,
    options
  );
}
